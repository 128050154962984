import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": false,
  "tileImage": "./tile-image-01.png",
  "tileTextColorScheme": "light",
  "types": ["product", "research"],
  "status": ["completed"],
  "title": "WASH-E",
  "subtitle": "",
  "description": "",
  "years": ["2014"],
  "institutions": ["aau-cph", "roskilde festival"],
  "location": "cph",
  "keywords": {
    "topics": ["re-design", "sustainability"],
    "tools": ["lego"]
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "Designer"
  }, {
    "name": "Gunnar",
    "role": "Designer"
  }, {
    "name": "Malte",
    "role": "Designer"
  }, {
    "name": "...",
    "role": "Designer"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* # WASH-E */
    }
    <h2>{`Description`}</h2>
    <p>{`We tore down a conventional washing machine and did a re-design proces in order to improve climate impact or user experience.
In our problem finding phase design analysis, research and interviews we found that changing the ball bearings would mean a total replacement of the washing machine.
We came up with a concept to make replace or repair the ball bearings easier.
Upgrading the washing machine.`}</p>
    <h2>{`Media`}</h2>
    <ul>
      <li parentName="ul">{`Drawing`}</li>
      <li parentName="ul">{`Report (Frontpage)`}</li>
      <li parentName="ul">{`Repairable washingmachine`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      